/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyTypes } from '../models/CompanyTypes';
import type { CreateCompanyLocationRequest } from '../models/CreateCompanyLocationRequest';
import type { CreateCompanyRequest } from '../models/CreateCompanyRequest';
import type { LinkIntegrationStudyRequest } from '../models/LinkIntegrationStudyRequest';
import type { UnlinkIntegrationStudyRequest } from '../models/UnlinkIntegrationStudyRequest';
import type { UpdateCompanyLocationRequest } from '../models/UpdateCompanyLocationRequest';
import type { UpdateCompanyLocationStatusRequest } from '../models/UpdateCompanyLocationStatusRequest';
import type { UpdateCompanyRequest } from '../models/UpdateCompanyRequest';
import type { UpdateIntegrationRequest } from '../models/UpdateIntegrationRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CompaniesService {

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiCompaniesCreateCompany(
requestBody?: CreateCompanyRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Companies/CreateCompany',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiCompaniesCreateCompanyLocation(
requestBody?: CreateCompanyLocationRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Companies/CreateCompanyLocation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static postApiCompaniesLinkIntegrationStudy(
requestBody?: LinkIntegrationStudyRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/Companies/LinkIntegrationStudy',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiCompaniesUnlinkIntegrationStudy(
requestBody?: UnlinkIntegrationStudyRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Companies/UnlinkIntegrationStudy',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiCompaniesUpdateCompany(
requestBody?: UpdateCompanyRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Companies/UpdateCompany',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiCompaniesUpdateCompanyLocation(
requestBody?: UpdateCompanyLocationRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Companies/UpdateCompanyLocation',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiCompaniesUpdateCompanyLocationStatus(
requestBody?: UpdateCompanyLocationStatusRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Companies/UpdateCompanyLocationStatus',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody 
     * @returns any OK
     * @throws ApiError
     */
    public static putApiCompaniesUpdateIntegration(
requestBody?: UpdateIntegrationRequest,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/Companies/UpdateIntegration',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param authorCognitoSub 
     * @param companyLocationId 
     * @returns any OK
     * @throws ApiError
     */
    public static deleteApiCompaniesRemoveCompanyLocation(
authorCognitoSub?: string,
companyLocationId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/Companies/RemoveCompanyLocation',
            query: {
                'authorCognitoSub': authorCognitoSub,
                'companyLocationId': companyLocationId,
            },
        });
    }

    /**
     * @param internalValue 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCompaniesGetCheckCompanyInternalValueExists(
internalValue?: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Companies/GetCheckCompanyInternalValueExists',
            query: {
                'internalValue': internalValue,
            },
        });
    }

    /**
     * @param companyId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCompaniesGetCompany(
companyId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Companies/GetCompany',
            query: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @param resultsPerPage 
     * @param pageNumber 
     * @param search 
     * @param orderBy 
     * @param sortBy 
     * @param companyId 
     * @param companyType 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCompaniesGetCompanies(
resultsPerPage?: number,
pageNumber?: number,
search: string = '',
orderBy: string = '',
sortBy: string = '',
companyId?: number,
companyType?: CompanyTypes,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Companies/GetCompanies',
            query: {
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
                'search': search,
                'orderBy': orderBy,
                'sortBy': sortBy,
                'companyId': companyId,
                'companyType': companyType,
            },
        });
    }

    /**
     * @param companyType 
     * @param resultsPerPage 
     * @param pageNumber 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCompaniesGetCompaniesByType(
companyType?: CompanyTypes,
resultsPerPage?: number,
pageNumber?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Companies/GetCompaniesByType',
            query: {
                'companyType': companyType,
                'resultsPerPage': resultsPerPage,
                'pageNumber': pageNumber,
            },
        });
    }

    /**
     * @param companyId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiCompaniesGetCompanyLocations(
companyId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Companies/GetCompanyLocations',
            query: {
                'companyId': companyId,
            },
        });
    }

}
