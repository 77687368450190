import * as actionTypes from "../../../store/actions";
import produce from "immer";
import {ParticipantStatusEnum} from "../../../shared/enums/participantStatusEnum";
import moment from "moment";
import {ParticipantDashboardState} from "../interfaces/participant-dashboard.state";
import {JotformResultEnum} from "../../../shared/enums/jotform-result.enum";
import {SurveyModel} from "../models/survey.model";
import {TaskStatusEnum} from "../../../shared/enums/task-status.enum";
import _ from "lodash";
import {FeatureEnum} from "../../../shared/enums/feature.enum";
import { UnknownAction} from "redux";


const initialState: ParticipantDashboardState = {
    isLoading: true,
    isChecking: false,
    isLoadingTrialList: false,
    participant: null,
    trial: null,
    trials: [],
    trialList: [],
    yourTrials: [],
    invitedTrials: [],
    site: null,
    hasBookingTask: false,
    showBookingForm: null,
    isBookingComplete: false,
    showAppointments: false,
    jotFormResult: null,
    surveyPassedOverride: false,
    isAddingParticipantToTrial: false,
    canRegister: false,
    redirect: false,
    hasRedirected: false,
    submissions: [],
    surveys: [],
    showFileRequests: false,
    showFileUpload: false,
    hasPhoneScreeningComplete: false,
    withdrawReason: null,
    disqualifyReason: null,
    isSelfWithdrawn: false
};

const participantDashboardReducer = (state: ParticipantDashboardState = initialState, action: UnknownAction): ParticipantDashboardState => {
    switch ((action as any).type) {
        case actionTypes.PARTICIPANT_DASHBOARD_LOAD:
            return produce(state, draft => {
                draft.isLoading = true;
            });
        case actionTypes.PARTICIPANT_DASHBOARD_TOGGLE_PROGRESS_STAGE:
            return produce(state, draft => {
                draft.participant.stages.forEach((stage: any, i: number) => {
                    if (stage.id === (action as any).stageId) {
                        stage.expanded = (action as any).expand;
                    }
                })
            });
        case actionTypes.PARTICIPANT_DASHBOARD_LOADED:
            return produce(state, draft => {

                const participant = (action as any).participant;

                draft.invitedTrials = (action as any).invitedTrials;
                const activeTrial = participant.trials.find((t:any) => t.id === participant.activeTrialId);
                draft.trial = (activeTrial) ? activeTrial : null;



                const activeWorkflow = participant.uiWorkflows.find((t:any) => t.trialId === participant.activeTrialId);

                const tasks = activeWorkflow?.stages.flatMap((s:any) => s.tasks.map((t:any) => t.name));
                const uniqTasks = _.uniq(tasks);
                draft.showFileRequests = uniqTasks.includes(FeatureEnum.FileRequests);
                draft.showFileUpload = uniqTasks.includes(FeatureEnum.FileUpload);

                // sort tasks and stages by priority
                activeWorkflow?.stages
                    .sort((a:any, b:any) => a.priority - b.priority)
                    .forEach((stage: any) => {
                        stage.tasks.sort((a:any, b:any) => a.priority - b.priority);
                    });

                participant.stages = activeWorkflow?.stages;
                participant.status = participant.activeTrialStatus;
                participant.overallStatus =  activeWorkflow?.overallStatus;
                participant.stageLabel =  activeWorkflow?.stageLabel;
                participant.activeStage = (participant.stageLabel === 'Stage 1')? 1 : 2;
                participant.preferredTimes = Array.isArray((action as any).preferredTimes) ? (action as any).preferredTimes : [];

                draft.trials = participant.trials;
                draft.canRegister = ![ParticipantStatusEnum.IN_PROGRESS].includes(participant.overallStatus);

                const yourTrials: any = [];
                draft.trials.forEach((t => {

                    const workflow = participant.uiWorkflows.find((w: any) => w.trialId === t.id);

                    const trial = {
                        id: t.id,
                        name: t.name,
                        description: t.description,
                        trialIdentifier: t.trialIdentifier,
                        protocolNumber: t.protocolNumber,
                        dateRegistered: moment.utc(workflow?.dateRegisteredForTrial).local().format("Do MMM, YYYY"),
                        stageLabel: workflow?.stageLabel,
                        status: workflow?.overallStatus,
                        isActiveTrial: activeTrial.id === t.id,
                        studyDetailsUrl: t.landingPage,
                        brochureUrl: t.trialBrochureUri
                    }

                    yourTrials.push(trial);
                }));
                draft.yourTrials = yourTrials;

                const activeSite = (participant.trialSites.length) ? participant.trialSites[participant.trialSites.length-1].site : null;
                draft.site = (activeSite) ? activeSite : null;

                participant.stages = produce(participant.stages, (draft_stages: any) => {

                   draft.hasBookingTask = (draft_stages && !!draft_stages[0].tasks.find((t:any) => t.name === 'bookPhonePrescreen'));
                   draft.showAppointments = (draft_stages && draft_stages[0].tasks[draft_stages[0].tasks.length-1].currentState === TaskStatusEnum.REFERRED);

                    draft_stages?.forEach((stage: any, si: number) => {
                        stage.canEdit = [];
                        const activeStep = stage.tasks.findIndex((t:any) => [TaskStatusEnum.AWAITING_ACTION, TaskStatusEnum.IN_PROGRESS, TaskStatusEnum.ATTEMPTED].includes(t.currentState));
                        stage.activeStepIndex = (activeStep === -1) ? ([TaskStatusEnum.REFERRED,TaskStatusEnum.RANDOMISED,TaskStatusEnum.WITHDRAWN,TaskStatusEnum.DISQUALIFIED].includes(stage.tasks[(stage.tasks.length-1)].currentState)) ? stage.tasks.length : activeStep : activeStep;
                        stage.expanded = (activeStep > -1);

                        for (let i=0; i < stage.tasks.length; i++){

                            if (stage.tasks[i].name === 'survey' && stage.tasks[i].currentState === TaskStatusEnum.PASSED){
                                draft.surveyPassedOverride = true; //NEW
                                draft.showBookingForm = true;
                            }

                            if (stage.tasks[i].name === 'bookPhonePrescreen' && [TaskStatusEnum.DONE,TaskStatusEnum.SKIPPED].includes(stage.tasks[i].currentState)){
                                draft.isBookingComplete = true;
                            }

                            if (stage.tasks[i].name === 'phonePrescreen' && [TaskStatusEnum.DONE,TaskStatusEnum.SKIPPED].includes(stage.tasks[i].currentState)){
                                draft.hasPhoneScreeningComplete = true;
                            }

                            if(stage.tasks[i].name === 'outcome' && stage.tasks[i].currentState === TaskStatusEnum.WITHDRAWN) {
                                draft.withdrawReason = stage.tasks[i].messageForDisplay.replace("Withdrawn: ","");
                                draft.isSelfWithdrawn = (stage.tasks[i].freeText === 'Participant requested withdrawal.');
                            }

                            if(stage.tasks[i].name === 'outcome' && stage.tasks[i].currentState === TaskStatusEnum.DISQUALIFIED) {
                                draft.disqualifyReason = stage.tasks[i].messageForDisplay.replace("Disqualified: ","");
                            }
                        }
                    });
                })

                draft.participant = participant;
                draft.isLoading = false;
            });
        case actionTypes.PARTICIPANT_JOTFORM_RESULT_CHECKING:
            return produce(state, draft => {
                draft.isChecking = true;
            });
        case actionTypes.PARTICIPANT_JOTFORM_RESULT:
            return produce(state, draft => {

                draft.submissions = (action as any).submissions;



                // get surveys from tasks
                const workflow = draft.participant.uiWorkflows.find((w: any) => w.trialId === draft.trial.id);
                const surveyTasks = workflow && workflow.stages.length ? workflow.stages[0].tasks.filter((t: any) => t.name === 'survey') : [];

                draft.surveys = surveyTasks.length ? surveyTasks.map((s: any) => {
                    const taskMeta = s.taskMetaData ? JSON.parse(s.taskMetaData) : null;
                    return new SurveyModel(s.displayName, s.id, taskMeta?.JotformSurveyId, s.currentState, s.messageForDisplay);
                }) : [];

                if ((action as any).submissions.length === 0){
                    draft.jotFormResult = JotformResultEnum.NO_SUBMISSION;
                } else if (draft.surveys.length === (action as any).submissions.length) {
                    const disqualified = surveyTasks.filter((s:any) => s.outcome?.trim() === JotformResultEnum.DISQUALIFIED);
                    const submissionDisqualify = (action as any).submissions.filter((s:any) => s.outcome?.trim() === JotformResultEnum.DISQUALIFIED);
                    draft.jotFormResult = (disqualified.length || submissionDisqualify.length) ? JotformResultEnum.DISQUALIFIED : JotformResultEnum.QUALIFIED;
                } else {
                    draft.jotFormResult = JotformResultEnum.PARTIAL_SUBMISSION;
                }


                // check below logic
                draft.isChecking = false;
                if (draft.jotFormResult === JotformResultEnum.QUALIFIED && !draft.isBookingComplete) {
                    draft.participant.stages?.forEach((stage: any, si: number) => {
                        for (let i=0; i < stage.tasks.length; i++) {
                            if (stage.tasks[i].name === 'survey' && [TaskStatusEnum.PASSED, TaskStatusEnum.PENDING_REVIEW].includes(stage.tasks[i].currentState)) {
                                draft.showBookingForm = true;
                                break;
                            }
                        }
                    });
                }
            });
        case actionTypes.PARTICIPANT_TRIAL_LIST_LOAD:
            return produce(state, draft => {
                draft.isLoadingTrialList = true;
            });
        case actionTypes.PARTICIPANT_TRIAL_LIST_LOADED:
            return produce(state, draft => {
                draft.isLoadingTrialList = false;
                draft.trialList = (action as any).trials.sort((a:any, b:any) => a.trialName.localeCompare(b.trialName));
            });
        case actionTypes.PARTICIPANT_ADD_TO_TRIAL:
            return produce(state, draft => {
                draft.isAddingParticipantToTrial = true;
            });
        case actionTypes.PARTICIPANT_ADDED_TO_TRIAL:
            return produce(state, draft => {
                draft.isAddingParticipantToTrial = false;
            });
        case actionTypes.PARTICIPANT_REDIRECT_TO_DASHBOARD:
            return produce(state, draft => {
                draft.redirect = (action as any).redirect;
            });
        case actionTypes.PARTICIPANT_REDIRECT_TO_FIND_A_TRIAL:
            return produce(state, draft => {
                draft.hasRedirected = true;
            });
        default:
            return state;
    }
};

export default participantDashboardReducer;


