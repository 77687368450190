const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
export const hasValidProfile = (country: string, phone: string, otcDisabled: boolean) => {
    try {
        if(!otcDisabled || country === 'Unspecified') {
            const code = country.toUpperCase();
            return phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, code), code);
        } else {
            return true;
        }
    } catch (err) {
        return false;
    }
}