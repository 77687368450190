import useAuth from '../../hooks/useAuth';
import {useNavigate} from "react-router-dom";
import React, {useEffect} from 'react';
import {RecordStatusesEnum} from "../../shared/enums/record-statuses.enum";
import {hasValidProfile} from "../../shared/utils/has-valid-profile";
import config from "../../config";

const RegisteredGuard = ({component}:{component:any}) => {
    const { isLoggedIn, isRegistered, user } = useAuth() as any;
    const navigate = useNavigate();


    useEffect(() => {
        
         if (!isLoggedIn) {
             navigate("/");
        }

        if (isRegistered && user.recordStatus !== RecordStatusesEnum.Unverified && hasValidProfile(user.locale, user.phoneNumber, user.otcSmsDisabled)) {
            return  navigate(config.defaultPath, {replace: true});
        }
        
    }, [isLoggedIn, isRegistered, navigate, user]);

    return <React.Fragment>{component}</React.Fragment>;
};

export default RegisteredGuard;
